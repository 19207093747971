<template>
  <div>
    <b-row class="py-2 py-lg-3">
      <b-col sm="12" md="6" lg="4">
        <div class="card rounded-large shadow-sm border-none">
          <div class="card-body">
            <div
              :class="
                form.is_consent === 1
                  ? 'icon-profile-consent mx-auto'
                  : 'icon-profile mx-auto'
              "
              :style="'background-image: url(' + form.picture + ');'"
            >
              <span v-if="!form.picture">
                {{
                  form.first_name_th
                    ? form.first_name_th.substring(1, 0).toUpperCase()
                    : ""
                }}
              </span>
            </div>

            <div class="position-check">
              <!-- <font-awesome-icon icon="check-circle" class="check-circle" /> -->
              <font-awesome-icon
                class="check-circle pointer"
                icon="pencil-alt"
                @click.prevent="sidebarEditProfile"
              />
            </div>
            <font-awesome-icon
              class="sync-icon"
              icon="sync-alt"
              @click.prevent="isTransfersModal = true"
            />
            <font-awesome-icon
              class="trash-icon"
              icon="trash-alt"
              @click.prevent="openModalDeleteUser"
            />
            <div class="customer-name">
              {{ form.first_name_th }} {{ form.last_name_th }} ({{
                form.member_id
              }})
            </div>
            <div
              class="box"
              v-if="
                $store.state.theme.themeLogo.result.display_profile_complete ==
                  '1' && profile.profile != '100'
              "
            >
              <div class="box-score">{{ Math.round(profile.profile) }}%</div>
              ความครบถ้วนของข้อมูล: {{ Math.round(profile.profile) }}%
            </div>
            <hr />
            <b-row class="customer-detail">
              <b-col sm="6">
                <div>Birthday</div>
                <div>
                  {{
                    $moment(form.birthday).year() == "0001" ||
                    $moment(form.birthday).year() == "1752" ||
                    !form.birthday
                      ? "-"
                      : form.birthday | moment($formatDateNew)
                  }}
                </div>
              </b-col>
              <b-col sm="6">
                <div>Gender</div>
                <div>{{ form.gender || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Register Date</div>
                <div>{{ form.created_time | moment($formatDateNew) }}</div>
              </b-col>
              <b-col sm="6">
                <div>Telephone</div>
                <div>{{ form.telephone }}</div>
              </b-col>
              <b-col sm="6">
                <div>Email</div>
                <div>{{ form.email || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Address</div>
                <div>
                  {{ fullAddr(form).trim() == "" ? "-" : fullAddr(form) }}
                </div>
              </b-col>
              <b-col sm="6">
                <div>Connected Channel</div>
                <div class="mt-2 d-flex" style="gap: 5px">
                  <img
                    class="market-image"
                    src="http://api-hourglass-admin-dev.dosetech.co/Upload/220323100329372.png"
                    alt="dcrm shop"
                  />
                  <div
                    v-for="connect of form.connect_channel"
                    :key="connect.id"
                  >
                    <img
                      class="market-image"
                      :src="connect.marketplaceUrl"
                      alt="marketplace image"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <hr />

                <template
                  v-if="
                    form.user_custom_field.filter(
                      (el) => el.user_answer.length > 0
                    ).length > 0
                  "
                >
                  <div class="content-between mb-2">
                    <b>Additional Questions</b>
                    <font-awesome-icon
                      @click="$refs.sidebarEditQues.show()"
                      icon="pencil-alt"
                      title="Edit"
                      class="main-color cursor-pointer"
                    />
                  </div>
                  <div
                    v-for="custom_field of form.user_custom_field"
                    :key="custom_field.id"
                  >
                    <template
                      v-if="
                        custom_field.user_answer &&
                        custom_field.user_answer.length > 0
                      "
                    >
                      <div
                        :class="[
                          custom_field.field_type_id == 10 ||
                          custom_field.field_type_id == 11
                            ? 'text-bold text-black '
                            : 'f-14',
                          { 'f-16': custom_field.field_type_id == 11 },
                          { 'f-18': custom_field.field_type_id == 10 },
                        ]"
                      >
                        {{ custom_field.name }}
                      </div>
                      <template v-if="custom_field.user_answer.length > 0">
                        <div
                          v-for="answer of custom_field.user_answer"
                          :key="answer.id"
                        >
                          <span v-if="custom_field.field_type_id == 8">
                            <b-img
                              :src="answer.answer"
                              class="w-100 h-100"
                            ></b-img>
                          </span>
                          <span v-else-if="custom_field.field_type_id == 5">
                            {{
                              $moment(answer.answer).format(
                                "DD MMM YYYY (HH:mm)"
                              )
                            }}
                          </span>

                          <span v-else-if="custom_field.field_type_id == 7">
                            {{ $moment(answer.answer).format("DD MMM YYYY") }}
                          </span>
                          <span v-else>{{ answer.answer }} </span>
                        </div>
                      </template></template
                    >
                  </div>
                </template>
              </b-col>
              <b-col cols="12">
                <div class="content-between mb-2">
                  <b>Additional Tag</b>
                  <font-awesome-icon
                    @click="$refs.sidebarEditTag.show()"
                    icon="pencil-alt"
                    title="Edit"
                    class="main-color cursor-pointer"
                  />
                </div>
                <div class="card">
                  <div class="p-1" v-if="form.user_tag.length > 0">
                    <b-row no-gutters
                      ><b-col
                        v-for="(tags, i) of form.user_tag"
                        :key="tags.id + '-' + i"
                        cols="3"
                        class="p-1"
                      >
                        <b-badge class="w-100 f-12" v-if="i < 11">{{
                          tags.name
                        }}</b-badge>
                        <div
                          v-else-if="i == 11"
                          class="text-center cursor-pointer"
                          @click="$refs.sidebarEditTag.show()"
                        >
                          + {{ form.user_tag.length + 1 - 12 }} Tags
                        </div>
                      </b-col></b-row
                    >
                  </div>
                  <div class="p-1 text-center" v-else>
                    <b-button @click="$refs.sidebarEditTag.show()"
                      >Add new Tag</b-button
                    >
                  </div>
                </div>
              </b-col>
              <b-col cols="12"
                ><TabNoteDetail
                  :noteList="noteList"
                  @updateNote="$emit('updateNote')"
              /></b-col>
            </b-row>
            <!-- <pre>{{ form }}</pre> -->
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="8">
        <div class="card rounded-large shadow-sm border-none">
          <b-tabs content-class="p-3">
            <b-tab title="Member Information">
              <MemberInfo :form="form" />
            </b-tab>
            <b-tab
              v-for="custom of customObject"
              :key="'custom-object-' + custom.id"
              :title="custom.name"
              @click="handleChangeCustomObject(custom.id)"
            >
              <CustomObject
                :ref="'refsCustomObject-' + custom.id"
                :data="custom"
                :id="custom.id"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <SidebarEditProfile
      ref="sidebarProfileEdit"
      :form="form"
      :memberLevelList="memberLevelList"
      :FieldList="FieldList"
      @updateProfile="updateProfile"
      :v="v"
      @openModalPoint="openModalPoint"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
    />
    <SidebarEditPersonalProfile
      ref="sidebarPersonalEdit"
      :list="customerQuestionList"
      @submitEditPersonal="submitEditPersonal"
      :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
    />
    <SidebarEditTag
      ref="sidebarEditTag"
      :form="form.user_tag"
      @fetch="$emit('fetch')"
    />
    <SidebarEditQuestion
      ref="sidebarEditQues"
      :form="form.user_custom_field"
      :branchCustomField="branchCustomField"
      @fetch="$emit('fetch')"
    />
    <SidebarEditAddress
      ref="sidebarAddressEdit"
      :form="form"
      @updateAddress="updateAddress"
    />
    <ModalDeleteUser ref="modalDeleteUser" @deleteUser="deleteUser" />
    <b-modal
      v-model="isTransfersModal"
      size="lg"
      hide-header
      centered
      @hidden="onHide"
    >
      <div class="header-tablepage mb-3">Transfer Data</div>
      <b-form-checkbox
        id="checkbox-1"
        v-model="transfers.IsTransferPoint"
        name="checkbox-1"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all points
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-2"
        v-model="transfers.IsTransferTransaction"
        name="checkbox-2"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all transactions and services
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-3"
        v-model="transfers.IsTransferCoupon"
        name="checkbox-3"
        :value="1"
        :unchecked-value="0"
      >
        Transfer all coupons
      </b-form-checkbox>
      <b-input-group class="mt-3">
        <b-form-input
          type="text"
          id="search"
          class="search-bar"
          name="tranfers-search"
          @keyup.enter="handleSearch"
          placeholder="Search Telephone, Member ID."
          v-model="search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
      </b-input-group>
      <div
        :class="[
          transfers.ToUserGUID == transfersToUser.user_guid
            ? 'highlight-border'
            : '',
          'rounded-lg container shadow-sm mt-3 py-3 border-trans',
        ]"
        v-if="isSearch"
      >
        <template v-if="isLoading">
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-else>
          <template v-if="!notFound">
            <b-row class="align-items-center text-black">
              <b-col cols="2" class="text-bold">Member ID</b-col>
              <b-col cols="2" class="text-bold">Telephone</b-col>
              <b-col cols="2" class="text-bold">First name</b-col>
              <b-col cols="2" class="text-bold">Last name</b-col>
              <b-col cols="2"></b-col>
              <div class="break-normal"></div>
              <b-col cols="2">{{ transfersToUser.member_id }}</b-col>
              <b-col cols="2">{{ transfersToUser.telephone }}</b-col>
              <b-col cols="2">{{ transfersToUser.first_name_th }}</b-col>
              <b-col cols="2">{{ transfersToUser.last_name_th }}</b-col>
              <b-col cols="2" class="ml-auto"
                ><b-button
                  variant="primary"
                  block
                  @click="transfers.ToUserGUID = transfersToUser.user_guid"
                >
                  <font-awesome-icon
                    v-if="transfers.ToUserGUID == transfersToUser.user_guid"
                    class=""
                    icon="check"
                    @click.prevent="sidebarEditProfile"
                  />
                  Select
                </b-button></b-col
              >
            </b-row>
          </template>
          <template v-else>
            <div class="text-info text-center">Member not found</div></template
          >
        </template>
      </div>
      <div v-if="showMessage != ''" class="text-danger mt-3">
        {{ showMessage }}
      </div>
      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button
              variant="outline-primary"
              @click.prevent="isTransfersModal = false"
              class="border-btn"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" @click.prevent="tranfersData">
              Confirm
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SidebarEditProfile from "@/components/report/customer/detail/profile/SidebarEditProfile";
import SidebarEditTag from "@/components/report/customer/detail/profile/SidebarEditTag";
import SidebarEditQuestion from "@/components/report/customer/detail/profile/SidebarEditQuestion";
import SidebarEditAddress from "@/components/report/customer/detail/profile/SidebarEditAddress";
import ModalDeleteUser from "./modal/DeleteUser";
// import MemberInfo from "./components/MemberInfo";
import TabNoteDetail from "@/components/report/customer/detail/TabNoteDetail";
import CustomObject from "./components/CustomObject";
import SidebarEditPersonalProfile from "@/components/report/customer/detail/profile/SidebarEditPersonalProfile";
import MemberInfo from "./components/MemberInfo";
export default {
  components: {
    SidebarEditProfile,
    SidebarEditAddress,
    SidebarEditPersonalProfile,
    SidebarEditTag,
    ModalDeleteUser,
    MemberInfo,
    CustomObject,
    SidebarEditQuestion,
    TabNoteDetail,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    customerQuestionList: {
      required: true,
      type: Array,
    },
    isLoadingPersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
    branchCustomField: {
      required: false,
    },
    noteList: {
      required: false,
    },
  },
  data() {
    return {
      customObject: [],
      profile: {},
      isTransfersModal: false,
      options: [
        { text: "Transfer all points", value: "0" },
        { text: "Transfer all transactions and services", value: "1" },
        { text: "Transfer all coupons", value: "2" },
      ],
      search: "",
      transfers: {
        IsTransferPoint: 1,
        IsTransferTransaction: 1,
        IsTransferCoupon: 1,
        FromUserGUID: "",
        ToUserGUID: "",
      },
      transfersToUser: {
        user_guid: "",
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        telephone: "",
      },
      notFound: false,
      isLoading: false,
      isSearch: false,
      showMessage: "",
    };
  },
  created() {
    this.getCustomObject();
    this.getProfileComplete();
  },
  methods: {
    async getProfileComplete() {
      const resp = await this.$Axios(
        `${process.env.VUE_APP_API}/customer/GetCompleteProfile/${this.$route.params.id}`
      );
      this.profile = resp.data.detail;
    },
    fullAddr(form) {
      let addr = `${form.home_address || ""} ${form.alley || ""} ${
        form.road || ""
      } ${form.town || ""} ${form.subdistrict || ""} ${form.district || ""} ${
        form.province || ""
      } ${form.zip_code || ""}`;
      return addr.trim() == "" ? "-" : addr;
    },
    async getCustomObject() {
      const resp = await this.$Axios.post(
        `${process.env.VUE_APP_API}/CustomObject/custom_object_head/customer_page`,
        {
          user_guid: this.$route.params.id,
        }
      );
      this.customObject = resp.data.detail;
    },
    sidebarEditProfile() {
      this.$refs.sidebarProfileEdit.show();
    },
    openModalDeleteUser() {
      this.$refs.modalDeleteUser.show();
    },
    async deleteUser(val) {
      this.$refs.modalDeleteUser.hide();

      let message = "";
      if (val == 0)
        message =
          "Do you confirm to delete account and move data to anonymous user";
      else
        message = "Do you confirm to delete account and all data permanently";
      this.confirmAlert({ message: message }).then(async (isCheck) => {
        if (isCheck.value) {
          this.$EventBus.$emit("showLoading");
          var response = null;
          if (val == 0) {
            response = await this.$Axios.delete(
              `${process.env.VUE_APP_API}/User/DeleteUserChangeAnnonymous/${this.form.user_guid}`
            );
          } else {
            response = await this.$Axios.delete(
              `${process.env.VUE_APP_API}/User/DeleteUserAllData/${this.form.user_guid}`
            );
          }
          this.$EventBus.$emit("hideLoading");
          if (response.data.result) {
            this.successAlert().then(() =>
              this.$router.replace("/report/customer")
            );
          } else {
            this.errorAlert(response.data.message);
          }
        }
      });
    },
    sidebarEditAddress() {
      this.$refs.sidebarAddressEdit.show();
    },
    updateAddress(value) {
      this.$emit("updateAddress", value);
    },
    updateProfile(value) {
      this.$emit("updateProfile", value);
    },
    openModalPoint(val) {
      this.$emit("openModalPoint", val);
    },
    sidebarEditProsonal() {
      this.$refs.sidebarPersonalEdit.show();
    },
    submitEditPersonal(val) {
      this.$emit("updatePersonal", val);
    },

    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;

      // let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    handleChangeCustomObject(id) {
      this.$refs["refsCustomObject-" + id][0].getData();
    },
    async handleSearch() {
      // console.log(object);
      this.showMessage = "";
      this.isSearch = true;
      this.isLoading = true;
      this.notFound = false;
      const resp = await this.axios.post("/customer/search_transfer_customer", {
        search: this.search,
      });
      if (resp.data.result) this.transfersToUser = resp.data.detail;
      else this.notFound = true;
      this.isLoading = false;
    },
    async tranfersData() {
      if (this.transfers.ToUserGUID != "") {
        this.showMessage = "";
        this.confirmAlert({
          message: `Do you want to transfer data from  ${this.form.first_name_th} ${this.form.last_name_th} (${this.form.member_id}) to ${this.transfersToUser.first_name_th} ${this.transfersToUser.last_name_th} (${this.transfersToUser.member_id})`,
          title: "Are you sure ?",
          icon: "warning",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
        }).then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            try {
              this.transfers.FromUserGUID = this.form.user_guid;
              this.$EventBus.$emit("showLoading");
              const resp = await this.axios.post(
                "/customer/transfer_customer",
                this.transfers
              );
              this.$EventBus.$emit("hideLoading");
              if (resp.data.result) {
                this.isTransfersModal = false;
                this.successAlert().then(() => this.$emit("getData"));
              } else {
                this.errorAlert(resp.data.message);
              }
            } catch (error) {
              this.$EventBus.$emit("hideLoading");
              console.error(error);
            }
          }
        });
      } else {
        this.showMessage = "Please select member to transfer data";
      }
    },
    onHide() {
      this.transfers = {
        IsTransferPoint: 1,
        IsTransferTransaction: 1,
        IsTransferCoupon: 1,
        FromUserGUID: "",
        ToUserGUID: "",
      };
      this.transfersToUser = {
        user_guid: "",
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        telephone: "",
      };
      this.notFound = false;
      this.isLoading = false;
      this.isSearch = false;
      this.showMessage = "";
      this.search = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-large {
  border-radius: 0.75rem;
  // border-color: unset;o
  overflow: hidden;
}
::v-deep .card-body {
  h4 {
    font-weight: 600;
    color: rgb(96, 96, 96);
  }

  color: black;
}

.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  bottom: 0px;
}
.name {
  font-weight: 600;
}
.bg-icon-point {
  background-color: #ffbe33;
  border-radius: 50px;
  color: #ffe8b8;
}
.icon-bd {
  color: var(--primary-color);
}
.bd-social {
  background-color: var(--secondary-color);
  border-radius: 50px;
  padding: 5px 9px;
  border: none;
}
.img-icon {
  height: auto;
  width: auto;
  max-width: 30px;
  max-height: 30px;
}
.lh-25 {
  line-height: 23px;
}
.collapse.width {
  transition: right 0.3s linear, max-width 0.3s linear;
  max-height: 100% auto;
  max-width: 100%;
  min-width: 50px;
  right: 0;
  position: relative;
  display: block;
}
.icon-profile {
  text-align: center;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  padding: 10px 0;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  position: relative;
}
.icon-profile-consent {
  // background-image: url(var(--background));
  background-size: contain;
  padding: 5px 0;
  text-align: center;
  border: 3px solid #2eb85c;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 15px;
  max-height: 15px;
}
.text-underline {
  text-decoration: underline;
}
.title-color {
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: transparent;
  border: none;
}
.check-circle {
  color: #000000;
  font-size: 1.25rem;
  padding: 3px;
  background-color: #acffc8;
  border-radius: 50px;
  border: 2px solid #2eb85c;
}
.position-check {
  top: 15px;
  left: 53%;
  position: absolute;
}
.question {
  color: gray;
}
.answer {
  color: #000;
}
.customer-name {
  text-align: center;
  font-size: 18px;
  margin: 10px 0px;
}
.customer-detail,
.customer-more-detail {
  row-gap: 0.75rem;
  div {
    div:first-child {
      white-space: nowrap;
      color: rgb(159, 159, 159);
      // font-size: 12px;
    }
  }
}
.customer-more-detail {
  // column-gap: 20px;
  row-gap: 10px;
}

body {
  min-height: 100vh;
  padding: 0 10vh;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-green {
  color: #2eb85c;
}
.text-error {
  color: red;
}

.market-image {
  width: 30px;
  height: auto;
}
.trash-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 18px;
}
.sync-icon {
  position: absolute;
  top: 20px;
  right: 60px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 18px;
}
.badge {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.box {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
}
.box-score {
  padding: 0.5rem 0.45rem;
  margin-right: 0.25rem;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
}
.border-trans {
  border: 1px solid transparent;
}
.highlight-border {
  border-color: var(--primary-color);
}
</style>
