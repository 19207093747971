<template>
  <div class="mt-3">
    <label>{{ label }}</label>
    <b-form-input
      v-model="data"
      :placeholder="placeholder"
      @change="handleChangeValue"
    ></b-form-input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String | Number,
    },
    placeholder: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    fieldtype: {
      required: false,
      type: Number,
    },
    questionId: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      data: this.value,
    };
  },
  methods: {
    handleChangeValue(value) {
      this.$emit("handleChangeValue", {
        answer: value,
        type: this.fieldtype,
        questionId: this.questionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>