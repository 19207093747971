<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="mt-3">
        <label>Search</label>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            placeholder="Search Order Number"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="mt-3">
        <label>Start Date-End Date</label>
        <div class="date-input">
          <span
            v-if="!filter.start_date"
            @click="$refs.dateStart.isOpen = true"
            class="w-40 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateStart.isOpen = true"
            class="w-50 pointer"
            >{{ filter.start_date | moment($formatDateNew) }}</span
          >
          <span class="px-2">-</span>
          <span
            v-if="!filter.end_date"
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >{{ filter.end_date | moment($formatDateNew) }}</span
          >
          <div :class="'icon-primary text-right'">
            <font-awesome-icon
              icon="calendar-alt"
              :class="'cursor-default color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <datetime
          ref="dateStart"
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          v-model="filter.start_date"
        >
        </datetime>
        <datetime
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          ref="dateEnd"
          v-model="filter.end_date"
        >
        </datetime>
      </div>
      <div class="mt-3">
        <InputSelect
          title="Branch"
          name="branch"
          :options="branch"
          :value="filter.branch_id"
          valueField="id"
          textField="name"
          :noPleaseSelect="true"
          @onDataChange="handleChangeType"
        />
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  data() {
    return {
      isShowSidebar: false,
    };
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    branch: {
      required: true,
      type: Array,
    },
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.$emit("filterAll", this.filter);
      this.hide();
    },
    clearFilter() {
      this.filter.search = null;
      this.filter.branch_id = 0;
      this.filter.start_date = null;
      this.filter.end_date = null;
    },
    handleChangeType(value) {
      this.filter.branch_id = value;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.w-40 {
  width: 40%;
}
label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
