<template>
  <b-sidebar
    id="sidebar-address-edit"
    ref="sidebarAddressEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Address Information</b-col>
      </b-row>
      <div class="my-3">
        <b-row>
          <b-col>
            <InputText
              textFloat="House Number"
              placeholder="House Number"
              type="text"
              name="home_address"
              v-model="form.home_address"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Building/Place"
              placeholder="Building/Place"
              type="text"
              name="alley"
              v-model="form.alley"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Road"
              placeholder="Road"
              type="text"
              name="road"
              v-model="form.road"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Sub-district"
              placeholder="Sub-district"
              type="text"
              name="subdistrict"
              v-model="form.subdistrict"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="District"
              placeholder="District"
              type="text"
              name="district"
              v-model="form.district"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Province"
              placeholder="Province"
              type="text"
              name="province"
              v-model="form.province"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Zip Code"
              placeholder="Zip Code"
              type="number"
              name="zip_code"
              v-model="form.zip_code"
              @onKeypress="handleMaxlength($event)"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
    };
  },
  methods: {
    handleMaxlength(e) {
      if (e.target.value.length == 5) {
        return e.preventDefault();
      }
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.$emit("updateAddress", this.form);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
