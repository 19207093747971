<template>
  <div class="mt-3">
    <b-form-group :label="label">
      <b-form-checkbox-group
        id="checkbox"
        :options="options"
        name="checked"
        v-model="selected"
        text-field="choice"
        value-field="choice_id"
        stacked
        @change="handleChangeCheckbox"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    fieldtype: {
      required: true,
      type: Number,
    },
    questionId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  created() {
    this.getChoiceSelect();
  },
  methods: {
    getChoiceSelect() {
      const chkChoice = this.options.some((el) => el.check === true);
      for (const choice of this.options) {
        if (chkChoice) {
          if (choice.check) {
            this.selected.push(choice.choice_id);
          }
        } else {
          if (choice.is_default) {
            this.selected.push(choice.choice_id);
          }
        }
      }
    },
    handleChangeCheckbox(val) {
      let groupArry = [];
      if (this.selected.length > 0) {
        for (const id of this.selected) {
          if (groupArry.length > 0) {
            const findId = groupArry.find((el) => el.answer_id === id);
            if (!findId) {
              const findValue = this.options.filter(
                (el) => el.choice_id === id
              );
              groupArry.push({
                answer_id: id,
                answer: findValue[0].choice,
              });
            }
          } else {
            const findValue = this.options.filter((el) => el.choice_id === id);
            groupArry.push({
              answer_id: id,
              answer: findValue[0].choice,
            });
          }
        }
      }
      this.$emit("handleChangeCheckbox", {
        answer: groupArry,
        type: this.fieldtype,
        questionId: this.questionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
