<template>
  <div class="mt-3">
    <label>{{ label }}</label>
    <b-form-select
      v-model="selected"
      :options="options"
      value-field="choice_id"
      text-field="choice"
      @change="handleChangeValue"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    fieldtype: {
      required: true,
      type: Number,
    },
    questionId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.getAnswer();
  },
  methods: {
    getAnswer() {
      for (const choice of this.options) {
        if (choice.check) {
          this.selected = choice.choice_id;
        }else{
          if(choice.is_default){
            this.selected = choice.choice_id;
          }
        }
      }
    },
    handleChangeValue(value) {
      const findValue = this.options.find((el) => el.choice_id === value);
      this.$emit("handleChangeDropdown", {
        answer_id : value,
        answer: findValue.choice,
        type: this.fieldtype,
        questionId: this.questionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>