import { render, staticRenderFns } from "./SidebarEditTag.vue?vue&type=template&id=635b1824&scoped=true&"
import script from "./SidebarEditTag.vue?vue&type=script&lang=js&"
export * from "./SidebarEditTag.vue?vue&type=script&lang=js&"
import style0 from "./SidebarEditTag.vue?vue&type=style&index=0&id=635b1824&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635b1824",
  null
  
)

export default component.exports